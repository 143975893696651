import React, { useEffect } from "react";
import css from "./style.module.css"

const SHADOW = (props) => {
   

    return (
        <div className={css.containerFluid} onClick={() => {props.onclick(false)}}>
                
        </div>
    )
}

export default SHADOW   
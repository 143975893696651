import React, { useState, useEffect } from "react";
import css from "./style.module.css"
import axios from "axios";
import { useParams } from "react-router-dom";


const generateRandomId = () => {
    return 'checkout-' + Math.random().toString(36).substr(2, 9); 
};

const CHECKOUT_PAGE = (props) => {
    
    const {productId} = useParams()
    const [product, setProduct] = useState()
    const [checkOutId, setCheckOutId] = useState(generateRandomId())

    const [payment, setPayment] = useState(null)

    useEffect(() => {
        const getProductDetail = async () => {
            try 
            {
                const response = await axios.get(`https://omn1group.com/api/v1/product/${productId}`)
                if(response) 
                {
                    if(response.status === 200)
                    {
                        setProduct(response.data.data)
                    }
                }
            }
            catch(err)
            {
                console.log(err)
            }
        }
        getProductDetail()
    },[productId])
    

    console.log(checkOutId)

    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.left}>
                    <p className={css.title}><label>{checkOutId}</label></p>
                    <div className={css.navBar}>
                        <p className={css.navItem}>Файл</p>
                        <p className={css.navItem}>Төрөл</p>
                        <p className={css.navItem}>Үнэ</p>
                        <p className={css.navItem}>Оруулсан</p>


                    </div>
                    <div className={css.cartContainer}>
                        <div className={css.imageContainer}>
                                <img src={require("../../Assets/276454.jpg")} className={css.img}/>
                                <p className={css.itemTitle}>{product && product.product_name ? product.product_name : "Нэр байхгүй байна"}</p>
                            </div>
                        <div className={css.detailContainer}>
                        <p className={css.smallSized}><label style={{fontWeight:400}}>Төрөл: </label>{product && product.category_name ? product.category_name : "Байхгүй"}</p>
                        <p className={css.smallSized}><label style={{fontWeight:400}}>Үнэ:</label> {product && product.price ? product.price + "₮" : "Байхгүй"}</p>
                        <p className={css.smallSized}><label style={{fontWeight:400}}>Оруулсан:</label> {product && product.username ? product.username : "Байхгүй"}</p>
                        <p className={css.detailItem}>{product && product.category_name ? product.category_name : "Байхгүй"}</p>
                        <p className={css.detailItem}>{product && product.price ? product.price + "₮" : "Байхгүй"}</p>
                        <p className={css.detailItem}>{product && product.username ? product.username : "Байхгүй"}</p>

                        </div>
                    </div>
                    <div className={css.payContainer}>
                        <p className={css.paymentTitle}>Төлбөр төлөх:</p>
                        <select className={css.select} onChange={(e) => {setPayment(e.target.value)}}>
                            <option className={css.option}>СОНГОХ</option>
                            <option className={css.option} value={"qpay"}>QPAY</option>

                        </select>
                        {
                            payment === "qpay"
                            ?
                            <div className={css.containerQpay}>
                                <div className={css.imageQpay}>
                                    <img src={require("../../Assets/qr.png")} className={css.qr}/>
                                </div>
                                <div className={css.statusContainer}>
                                    <button className={css.checkButton}>
                                            Төлбөр шалгах
                                    </button>
                                </div>
                            </div>
                        : null
                        }

                    </div>
                </div>
                <div className={css.right}>

                </div>
            </div>
        </div>
    )
}   

export default CHECKOUT_PAGE
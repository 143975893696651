import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const ProductContext = createContext()

export const ProductProvider = ({children}) => {
    const [products, setProducts] = useState([])

    useEffect(() => {
        const getCategories = async () => {
            try 
            {
                const response = await axios.get("https://omn1group.com/api/v1/product")
                if(response)
                {
                    if(response.status === 200)
                    {
                        setProducts(response.data.data)
                    }
                }
            }   
            catch(err)
            {
                console.log(err)
            }
        }
        getCategories()
    }, [])

    return (
        <ProductContext.Provider value={{products}}>
            {children}
        </ProductContext.Provider>
    )
}

export const useProduct = () => {
    return useContext(ProductContext)
}
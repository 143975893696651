import React from "react";
import css from "./style.module.css"

const TANII_MEDEELEL = (props) => {
    return (
        <div className={css.containerFluid}>
            <p className={css.title}>Таны мэдээлэл</p>
            <div className={css.hr}>

            </div>

            
        </div>
    )
}

export default TANII_MEDEELEL
import React, { useState } from "react";
import css from "./style.module.css"
import NON_TOUCHABLE_STAR from "../../STAR/NON_TOUCHABLE";
import { Link } from "react-router-dom";

const PRODUCT_CARD = (props) => {
    
    if (props.loading) {
        return (
          <div className={css.containerFluid}>
            <div className={css.container}>
              <div className={css.skeletonLoader}>
                <div className={css.image}></div>
    
                <div className={css.detailContainer}>
                  <div className={css.detailContainerSection}>
                    <p className={css.itemTitle}><label style={{opacity:0}}>{props.title}</label></p> 
                    <div className={css.descContainer}>
                      <p className={css.desc}><label style={{opacity:0}}>{props.desc}</label></p> 
                    </div>
                    <div className={css.buttonContainer}>
                      <button className={css.buyBtn}></button> 
                      <button className={css.buyBtn}></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    
      console.log(props)
    
    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.imageContainer}>
                    <img src={`https://omn1group.com${props.image}`}  className={css.image}/>
                </div >
                <div className={css.detailContainer}>
                    <div className={css.detailContainerSection}>
                        <div className={css.detailContainerNav}>
                            <p className={css.itemTitle}>{props.title}</p>
                        </div>
                        <NON_TOUCHABLE_STAR star={props.rating ? props.rating : null}/>
                        <div className={css.descContainer}>
                            <p className={css.desc}>{props.desc}</p>
                        </div>
                        <div className={css.buttonContainer}>
                            <Link to={`/files/${props.category}/${props.id}/checkout`}>
                            <button className={css.buyBtn}>
                                {props.price}₮
                            </button>
                            </Link>

                            <Link to={`/files/${props.category ? props.category : null}/${props.id ? props.id : null}`} style={{marginLeft:"20px", backgroundColor:"orange", borderRadius:"5px"}}>
                            <button className={css.buyBtn} style={{backgroundColor:"orange", borderRadius:"10px"}}>
                                Дэлгэрэнгүй үзэх
                            </button> 
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PRODUCT_CARD
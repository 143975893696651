import React, { useState } from "react";
import css from "./style.module.css"
import NON_TOUCHABLE_STAR from "../../Components/STAR/NON_TOUCHABLE";

const FAVOURITES = (props) => {
    const [loved , setLoved] = useState(false)
    
    const toggleLoved = () => {
        setLoved(prev => !prev)
    }
    return (
       <div className={css.sectionContainer}>   
       <h2 style={{textAlign:"start", width:"95%"}}>Таалагдсан</h2>
         <div className={css.containerFluid}>
        <div className={css.imageContainer}>
            <img src={require("../../Assets/2898.png")} className={css.img}/> 
        </div>
        <div className={css.detailContainer}>
            <div className={css.detailContainerNav}>
                <label className={css.itemTitle}>God Of War</label>
                <img src={loved ? require("../../Assets/heartActive.png") : require("../../Assets/heart.png")} className={css.favourite} onClick={toggleLoved}/>
            </div>
            <NON_TOUCHABLE_STAR star={4} />
            <label className={css.desc}>God of War is an action-adventure game franchise created by David Jaffe and developed by Sony's Santa Monica Studio. It began in 2005 on the PlayStation 2 (PS2) video game console and has become a flagship series for PlayStation, consisting of nine installments across multiple platforms.</label>
            <div className={css.btnContainer}>
            <button className={css.buyBtn}>23.499₮</button>
            <button className={css.buyBtn}>Дэлгэрэнгүй үзэх</button>


            </div>

        </div>
    </div>
       </div>
    )
}

export default FAVOURITES
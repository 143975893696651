import React, { useState } from "react";
import css from "./style.module.css"
import TANII_MEDEELEL from "../../Components/PROFILE/TANII_MEDEELEL";
import KONTENT from "../../Components/PROFILE/KONTENT";
import ORDER from "../../Components/PROFILE/ZAHIALGA";

const PROFILE = (props) => {
    const [containerType, setContainerType] = useState("info")

    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.left}>
                    <p className={css.title}>Профайл</p>
                    <div className={css.hr}>

                    </div>
                    <button className={containerType === "info" ? css.btnMenuActive : css.btnMenu}  onClick={() => {setContainerType("info")}}>Таны мэдээлэл</button>
                    <button className={containerType === "order" ? css.btnMenuActive : css.btnMenu} onClick={() => {setContainerType("order")}}>Захиалга</button>
                    <button className={containerType === "content" ? css.btnMenuActive : css.btnMenu} onClick={() => {setContainerType("content")}}>Контент</button>    
                </div>
                <div className={css.right}>
                    {
                        containerType === "info" 
                        ?
                        <TANII_MEDEELEL/>

                        : 
                        containerType === "order"
                        ?
                        <ORDER/>
                        : containerType === "content"

                        ?
                        <KONTENT/>
                        : 
                        null
                    }
                </div>  
            </div>
        </div>
    )
}

export default PROFILE
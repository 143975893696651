import React from "react";
import css from "./style.module.css"

const CONTENT = (props) => {
    return (
        <div className={css.containerFluid}>

        </div>
    )
}

export default CONTENT
import React from "react";
import css from "./style.module.css"
import NEWS_CARD from "../../CARD/NEWS_CARD";

const HOME_BANNER_2 = (props) => {
    return (
        <div className={css.containerFluid}>
                <div className={css.left}>
                    <p className={css.leftTitle}>BANNER</p>
             
                </div>
                <div className={css.right}>
                    <div className={css.rightContainer}>
                        <p className={css.leftTitle} style={{color:"white"}}>Мэдээ мэдээлэл</p>
                        <div className={css.hr}></div>
                        <NEWS_CARD/>
                        <NEWS_CARD/>
                        <NEWS_CARD/>
                        <button className={css.moreBtn}>Цааш үзэх <img src={require("../../../Assets/right.png")} className={css.btnRightArrow}/></button>
                    </div>
                </div>
        </div>
    )
}

export default HOME_BANNER_2
import React, { useEffect, useState, useCallback  } from "react";
import css from "./style.module.css"
import { useProduct } from "../../Context/productContext";
import { useCategory } from "../../Context/categoryContext";
import { Link, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import PRODUCT_CARD from "../../Components/CARD/PRODUCT_CARD";


const CATEGORY_PAGE = (props) => {

    const {categories } = useCategory()
    const {products} = useProduct()
    const {category} = useParams()

    const [product, setProduct] = useState([])

    useEffect(() => {
        const handleFilter = () => {
            if(category === "all")
            {
                setProduct(products)
                return;
            }
            try 
            {
                const filter = products.filter((item) => item.category_name === category)
                setProduct(filter)
            }
            catch(err)
            {
                console.log(err)
            }
        }
        handleFilter()
    }, [category, products])




    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.left}>
                    <label className={css.title}>Төрлүүд</label>
                    <div className={css.hr}>

                    </div>
                    
                    <ul className={css.list}>
                        <Link to={`/files/all`} className={css.link}>
                            <li className={css.listItem}>
                                <div className={css.active}>
                                    {category === "all" && <img src={require("../../Assets/activeCheck.png")} className={css.activeCheck}/>}
                                </div>
                                <p className={css.name}>Бүх</p>
                            </li>
                        </Link>
                        {
                            categories && categories.length > 0 

                            ? 

                            categories.map((e, i) => (
                            <Link to={`/files/${e.name}`} className={css.link}>
                             <li className={css.listItem} key={i}>
                                <div className={css.active}>
                                    {category === e.name && <img src={require("../../Assets/activeCheck.png")} className={css.activeCheck}/>}
                                </div>
                                <p className={css.name}>{e.name}</p>
                            </li>
                            </Link>
                            ) )

                            : 

                            "Төрөл байхгүй байна"
                        }
                    </ul>

                </div>
              
                    <div className={css.right}>
                    <div className={css.rightContainer}>
                    
                    {
                        product && product.length > 0 

                        ?

                        product.map((e, i) => (
                            <PRODUCT_CARD title={e.product_name} desc={e.short_desc} price={e.price} rating={e.rating} category={e.category_name} id={e.id} image={e.imgUrl} />
                        ))
                    
                        :

                        null
                    }
    
                    
                    <div className={css.paginationContainer}>
                                <div className={css.pagesContainer}>
                                    <div className={css.mapPage}>
                                        <img src={require("../../Assets/arrowLeft.png")}/>
                                    </div>
                                    <div className={css.mapPage}>
                                        1
                                    </div>
                                    <div className={css.mapPageActive}>
                                        2
                                    </div>
                                    <div className={css.mapPage}>
                                        3
                                    </div>
                                    <div className={css.mapPage}>
                                        4
                                    </div>
                                    <div className={css.mapPage}>
                                        5
                                    </div>
                                    <div className={css.mapPage}>
                                        <img src={require("../../Assets/arrowRight.png")}/>
                                    </div>
                                </div>
                    </div>

                    </div>
                </div>  
                
            </div>
        </div>
    )
}

export default CATEGORY_PAGE
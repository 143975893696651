import React, { useState } from "react";
import css from "./style.module.css"
import BANNER_HOME from "../../Components/HOME/1. BANNER";
import FILES_CONTAINER from "../../Components/HOME/2. FILES_CONTAINER";
import HOME_BANNER_2 from "../../Components/HOME/3.BANNER";
import { useAuth } from "../../Context/authContext";
import LOADING from "../../Components/LOADING";

const Home = (props) => {

        

    return (
        <div className={css.containerFluid}>

            <div className={css.container}>
                <BANNER_HOME setModal={props.setModal} setModalType={props.setModalType}/>  
                <FILES_CONTAINER/>
                <HOME_BANNER_2/>
            </div>
        </div>
    )
}

export default Home
import React from "react";
import css from "./style.module.css"

const LIST_OF_CONTENT = (props) => {
    return (
        <div className={css.containerFluid}>
            ss
        </div>
    )
}

export default LIST_OF_CONTENT
import React, { useEffect, useState } from "react";
import css from "./style.module.css"
import LEFT_PRODUCT_DETAIL from "../../Components/PRODUCT_DETAIL/1. LEFT";
import RIGHT_PRODUCT_DETAIL from "../../Components/PRODUCT_DETAIL/2. RIGHT";
import { useParams } from "react-router-dom";
import axios from "axios";

const PRODUCT_DETAIL = (props) => {

    const {productId} = useParams()

    const [product, setProduct] = useState()


    useEffect(() => {
        const getProductDetail = async () => {
            try 
            {
                const response = await axios.get(`https://omn1group.com/api/v1/product/${productId}`)
                if(response) 
                {
                    if(response.status === 200)
                    {
                        setProduct(response.data.data)
                    }
                }
            }
            catch(err)
            {
                console.log(err)
            }
        }
        getProductDetail()
    },[productId])
    

    return (
        <div className={css.containerFluid}>
            <div className={css.topContainer}>
                <LEFT_PRODUCT_DETAIL product={product}/>
                <RIGHT_PRODUCT_DETAIL product={product}/>
            </div>
        </div>
    )

}

export default PRODUCT_DETAIL
import React from "react";
import css from "./style.module.css"

const NEWS_CARD = (props) => {
    return (
        <div className={css.containerFluid}>
                <div className={css.imageContainer}>
                    <div className={css.hoverImageContainer}>
                        <p className={css.readHover}>УНШИХ <img src={require("../../../Assets/right.png")} className={css.rightArrow}/></p>
                    </div>
                    <img src={require("../../../Assets/medee.jpg")} className={css.img}/>
                </div>
                <div className={css.detailContainer}>
                    <div className={css.detailContainerNav}>
                        <p className={css.itemTitle}>Ерөнхий сайд, парламентад суудалтай бүх намын удирдлага, УИХ, Засгийн газрын гишүүд Шанхайн экспод оролцож байна</p>
                    </div>
                    <p className={css.desc}>БНХАУ-ын Худалдааны яам, Шанхай хотын Ардын Засгийн газраас жил бүр зохион байгуулдаг “Хятадын олон улсын импортын VII экспо”-гийн нээлт өнөөдөр болж, Монгол Улсын Ерөнхий сайд Л.Оюун-Эрдэнэ оролцож үг хэлэв.</p>
                    <div className={css.bottomContainer}>
                        <div className={css.containerItem}>
                            <img src={require("../../../Assets/eye.png")} className={css.iconBottom}/>
                            <p className={css.bottomContainerTxt}>4512</p>
                        </div>
                        <div className={css.containerItem}>
                            <img src={require("../../../Assets/calendar.png")} className={css.iconBottom}/>
                            <p className={css.bottomContainerTxt}>2024-11-5</p>
                        </div>
                        <div className={css.containerItem}>
                            <img src={require("../../../Assets/clock.png")} className={css.iconBottom}/>
                            <p className={css.bottomContainerTxt}>13:02</p>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default NEWS_CARD
import React, { useState } from "react";
import css from "./style.module.css"
import user from "../../../Assets/user.png"
import emailIcon from "../../../Assets/email.png"
import passwordIcon from "../../../Assets/password.png"
import exit from "../../../Assets/exit.png"
import axios from "axios";
import LOADING from "../../LOADING";
import { useMessage } from "../../../Context/messageContext";

const CONTAINER_REGISTER = (props) => {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isLoading , setIsLoading] = useState(false)

    const [errorMessage , setErrorMessage] = useState("")

    const [token, setToken] = useState("")
    const {setModalMessage} = useMessage()


    const handleRegister = async () => {
        try 
        {
            const response = await axios.post("https://omn1group.com/api/v1/auth/v1/register", {username, email , password , confirmPassword})
            if(response.status === 200)
            {
                setIsLoading(true)
                props.setModal(false)
                setTimeout(() => {
                    setIsLoading(false)
                    setModalMessage("Хэрэглэгчийн бүртгэл амжилттай үүслээ.")
                    
                },2000)
            }
        }
        catch(err)
        {
            setErrorMessage(err.response.data.message)
        }
    } 


    const handleToken = async () => {
            try {
                const response = await axios.post(
                    "https://merchant.qpay.mn/v2/auth/token", 
                    {},
                    {
                        headers: {
                            'Authorization': 'Basic ' + btoa('BOXTECH_MN' + ':' + 'SW5jzYKU')
                        }
                    }
                );
                console.log(" TOKEN AWAH",response.data);
                setToken(response.data.access_token)

            } catch (err) {
                console.log("Error:", err);
            }
    };

    console.log("awsanToken" , token)

    const [invoiceCode, setInvoiceCode] = useState("");
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");
    const [receiverCode, setReceiverCode] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
  
    // Access token-ийг header-д ашиглана
    
  
    // Нэхэмжлэх үүсгэх функц
    const createInvoice = async () => {
      const invoiceData = {
        invoice_code: "TEST_INVOICE",
        sender_invoice_no: "123455678",
        invoice_receiver_code: "83",
        sender_branch_code: "BRANCH1",
        invoice_description: "Order No1311 200.00",
        enable_expiry: "false",
        allow_partial: false,
        minimum_amount: null,
        allow_exceed: false,
        maximum_amount: null,
        amount: 200,
        callback_url: "https://bd5492c3ee85.ngrok.io/payments?payment_id=12345678",
        sender_staff_code: "online",
        note: null,
        invoice_receiver_data: {
          register: "UZ96021105",
          name: "Ganzul",
          email: "test@gmail.com",
          phone: "88614450"
        },
        lines: [
          {
            tax_product_code: "6401",
            line_description: "Order No1311 200.00.",
            line_quantity: "1.00",
            line_unit_price: "200.00",
            note: "-.",
            discounts: [
              {
                discount_code: "NONE",
                description: "discounts",
                amount: 10,
                note: "discounts"
              }
            ],
            surcharges: [
              {
                surcharge_code: "NONE",
                description: "Хүргэлтийн зардал",
                amount: 10,
                note: "Хүргэлт"
              }
            ],
            taxes: [
              {
                tax_code: "VAT",
                description: "НӨАТ",
                amount: 20,
                note: "НӨАТ"
              }
            ]
          }
        ]
      };
    
      try {
        const response = await axios.post(
          "https://merchant.qpay.mn/v2/invoice", 
          
          {
            headers: {
              "Authorization": `Bearer ${encodeURIComponent(token)}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({invoiceData})
          },
          
        );

        console.log(response)
  
        if (response) {
          setSuccess("Нэхэмжлэл амжилттай үүслээ!");
          setError("");
        }
      } catch (err) {
        setSuccess("");
        setError("Нэхэмжлэх үүсгэхэд алдаа гарлаа: " + err.message);
        console.log(err)
      }
    };



        if(isLoading) return <LOADING/>

    return (
        <div className={css.containerFluid}>
            {
                success 
            }

            {
                error
            }
            <div className={css.exitContainer}>
                <img src={exit} className={css.exit} onClick={()=>{props.setModal(false)}}/>    
            </div>
            <p className={css.title}>БҮРТГҮҮЛЭХ</p>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={user} className={css.icon}/>
                </div>
                <input type="text" placeholder="Нэвтрэх нэр" className={css.input} onChange={(e) => {setUsername(e.target.value)
                                                                                                     setErrorMessage("")
                }}/> 
                
            </div>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={emailIcon} className={css.icon}/>
                </div>
                <input type="text" placeholder="И-Мейл хаяг" className={css.input} onChange={(e)=>{setEmail(e.target.value)
                                                                                                    setErrorMessage("")
                }}/> 
                
            </div>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={passwordIcon} className={css.icon}/>
                </div>
                <input type="text" placeholder="Нууц үг" className={css.input} onChange={(e)=> {setPassword(e.target.value)
                                                                                                setErrorMessage("")
                }}/> 
                
            </div>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={passwordIcon} className={css.icon}/>
                </div>
                <input type="text" placeholder="Нууц үг давтах" className={css.input} onChange={(e)=>{setConfirmPassword(e.target.value)
                                                                                                      setErrorMessage("")   
                }}/> 
                
            </div>
                <p className={css.errorMessage}>{errorMessage}</p>
            <button className={css.buttonRegister} onClick={handleRegister}>Бүртгүүлэх</button>
            <button className={css.buttonRegister} onClick={handleToken}>QPAY Токен авах</button>
            <button className={css.buttonRegister} onClick={createInvoice}>QPAY Нэхэмжлэл авах</button>
            <p className={css.bottomTxt}>Бүртгэлтэй юу тэгвэл <label className={css.linkLogin} onClick={()=>{props.setIsLogin("login")}}>энд</label> дарна уу</p>

        </div>
    )
}

export default CONTAINER_REGISTER
import React from "react";
import css from "./style.module.css"

const LOADING = (props) => {
    return (
        <div className={css.containerFluid}>
            <img src={require("../../Assets/loading.gif")}/>
        </div>
    )
}

export default LOADING
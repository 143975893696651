import React from "react";
import css from "./style.module.css"

const ORDER = (props) => {
    return (
        <div className={css.containerFluid}>
            <p className={css.title}>Захиалга</p>
            <div className={css.hr}>

            </div>
            <div className={css.listContainer}>
                <div className={css.listItem}>
                    <div className={css.box}>
                        <p className={css.listItemNav}>Огноо</p>
                        <p className={css.listItemNav}>Тайлбар</p>
                        <p className={css.listItemNav}>Статус</p>
                        <p className={css.listItemNav}>Төлбөр</p>
                        <p className={css.listItemNav}>Дууссан огноо</p>
                    </div>
                </div>
                <div className={css.itemsContainer}>
                    
                    <div className={css.boxItem}>
                    <p className={css.smallSizedTitle}>Огноо:</p>

                    <p className={css.listItemTxt}>2024-11-13</p>
                    <p className={css.smallSizedTitle}>Тайлбар:</p>
                    <p className={css.listItemTxt}>HM3 захиалга</p>
                    <p className={css.smallSizedTitle}>Статус:</p>
                    <p className={css.listItemTxtStatus}><label>Хүлээгдэж байгаа</label></p>
                    <p className={css.smallSizedTitle}>Төлбөр:</p>

                    <p className={css.listItemTxtStatusGreen}><label>Төлсөн</label></p>
                    <p className={css.smallSizedTitle}>Дууссан огноо:</p>

                    <p className={css.listItemTxt}>2024-11-15</p>

                    </div>
                    <div className={css.boxItem}>
                    <p className={css.listItemTxt}>2024-11-13</p>
                    <p className={css.listItemTxt}>HM3 захиалга</p>
                    <p className={css.listItemTxtStatus}><label>Хүлээгдэж байгаа</label></p>
                    <p className={css.listItemTxtStatusGreen}><label>Төлсөн</label></p>
                    <p className={css.listItemTxt}>2024-11-15</p>

                    </div>

                </div>
            </div>

            
        </div>
    )
}

export default ORDER
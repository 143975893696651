import React, { useState } from "react";
import css from "./style.module.css"
import logo from "../../../Assets/logo.png"
import search from "../../../Assets/search.png"
import question from "../../../Assets/question.png"
import folder from "../../../Assets/folder.png"
import user from "../../../Assets/user.png"
import favourite from "../../../Assets/favourite.png"
import burger from "../../../Assets/burger.png"
import { useAuth } from "../../../Context/authContext";
import { Link } from "react-router-dom";

const TOP_NAV = (props) => {
    const {isAuthenticated} = useAuth()
    const [search, setSearch] = useState("")
    const [isSearch , setIsSearch] = useState(false)

    return (
        <div className={css.containerFluid} onClick={() => {setIsSearch(false)}}>
                <div className={css.container}>
 
               
                   <div className={css.logoContainer}>
                     <Link to={"/"}><img src={logo} className={css.logo} alt="logo" /></Link>
                   </div> 
                   
                    
                  
                    <div className={!search ? css.searchContainer : css.searchContainerActive}>
                    <div className={css.searchIconContainer}>
                        <img src={require("../../../Assets/search.png")} className={css.searchIcon} alt="s"/>
                    </div>
                        <input placeholder="Хайх" className={css.input} onChange={(e)=> {setSearch(e.target.value)}}/>
                       
                    </div>
                    <div className={css.iconContainer}>
                        <div className={css.menuContainer}>
                            <div className={css.iconC}>
                                <img src={question} className={css.icon}/>
                            </div>
                            <div className={css.iconTxtContainer}>
                                <p className={css.txtIcon}>Асуулт</p>
                            </div>
                        </div>
                        <Link to={"/content"}>
                        <div className={css.menuContainer}>
                            <div className={css.iconC}>
                                <img src={folder} className={css.icon}/>
                            </div>
                            <div className={css.iconTxtContainer}>
                                <p className={css.txtIcon}>Контент</p>
                            </div>
                            </div>
                        </Link>
                        {
                            !isAuthenticated
                            ? 
                            <div className={css.menuContainer} onClick={() => {props.setModal(true)}}>
                            <div className={css.iconC}>
                                <img src={user} className={css.icon}/>
                            </div>
                            <div className={css.iconTxtContainer} >
                                <p className={css.txtIcon}>Нэвтрэх</p>
                            </div>
                            </div> 
                            : 
                            <Link to={"/profile"}>
                            <div className={css.menuContainer}>
                            <div className={css.iconC}>
                                <img src={user} className={css.icon}/>
                            </div>
                            <div className={css.iconTxtContainer} >
                                <p className={css.txtIcon}>Профайл</p>
                            </div>
                            </div>
                            </Link>
                        }
                        <Link to={"/favourites"}> 
                            <div className={css.menuContainer}>
                            <div className={css.iconC}>
                                <img src={favourite} className={css.icon}/>
                            </div>
                            <div className={css.iconTxtContainer}>
                                <p className={css.txtIcon}>Хадгалсан</p>
                            </div>
                            </div>
                        </Link>
                    </div>


                    <div className={css.rightIconsSmall}>
                        {/** НЭВТРЭХ ICON */}
                       {
                        !isAuthenticated 
                        ?
                        <div className={css.smallSizedSearch} onClick={() => {props.setModal(true)}}>
                            <img src={user} className={css.searchIcon}/>
                        </div> 
                        :
                        <div className={css.smallSizedSearch} >
                            <img src={user} className={css.searchIcon}/>
                        </div> 
                       }
                        <div className={css.burgerMenuContainer} onClick={() => {props.setBurgerMenu(true)}}>
                            <img src={burger} className={css.burgerMenu}/>
                        </div>     
                    </div>
                        
                </div>
        </div>
    )
}

export default TOP_NAV
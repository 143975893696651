import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const CategoryContext = createContext()

export const CategoryProvider = ({children}) => {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        const getCategories = async () => {
            try 
            {
                const response = await axios.get("https://omn1group.com/api/v1/category")
                if(response)
                {
                    if(response.status === 200)
                    {
                        setCategories(response.data.data)
                    }
                }
            }
            catch(err)
            {
                console.log(err)
            }
        }
        getCategories()
    }, [])


    return (
        <CategoryContext.Provider value={{categories}}>
            {children}
        </CategoryContext.Provider>
    )
}

export const useCategory = () => {
    return (
        useContext(CategoryContext)
    )
}


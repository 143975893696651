import React from "react";
import css from "./style.module.css"

const BURGER_MENU = (props) => {

    const handleLogin = (type) => {
        try 
        {   
            props.setBurgerMenu(false)
            props.setModal(true)
            props.setLoginType(type)
        }
        catch(err)
        {
            console.log(err)
        }
    }

    return (
        <div className={`${css.containerFluid} ${props.menu ? css.active : null}`}>
            <div className={css.container}>
                <div className={css.navBar}>
                    <img src={require("../../Assets/logo.png")} className={css.logo}/>
                    <div className={css.exitContainer} onClick={()=>{props.setBurgerMenu(false)}}>
                        <img src={require("../../Assets/close.png")} className={css.exit}/>
                    </div>
                </div>
                <button className={css.loginBtn} onClick={() => {handleLogin("login")}}>
                        Нэвтрэх
                </button>
                <button className={css.registerBtn} onClick={() => {handleLogin("register")}}>
                    Бүртгүүлэх
                </button>
                <div className={css.hr}>

                </div>
                <ul className={css.list}>
                    <li className={css.listItem}>Бүх төрөл</li>
                </ul>
                <ul className={css.list}>
                    <li className={css.listItem}>Танд таалагдсан</li>
                </ul>
                <ul className={css.list}>
                    <li className={css.listItem}>Таны контент</li>
                </ul>
                <ul className={css.list}>
                    <li className={css.listItem}>Асуулт</li>
                </ul>
            </div>
        </div>
    )
}

export default BURGER_MENU
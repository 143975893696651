import React from "react";
import css from "./style.module.css"

const RIGHT_PRODUCT_DETAIL = (props) => {
    
    console.log(props.product)

    return (
        <div className={css.containerFluid}>
            <p className={css.title}>{props.product && props.product.product_name ? props.product.product_name : "Гарчиг байхгүй байна."} </p>
            <p className={css.publisher}>Оруулсан: <label style={{textDecoration:"underline", fontWeight:"400"}}>{props.product && props.product.username ? props.product.username : "өгөгдөл байхгүй"}</label></p>
            
            <p>{props.product && props.product.short_desc ? props.product.short_desc : "Богино тайлбар байхгүй"}</p>

            <div className={css.containerSub}>

            </div>

            <button className={css.button}>
                {props.product && props.product.price ? props.product.price + "₮" : "Үнэ байхгүй"}
            </button>
            <button className={css.buttonAddFavourite}>
                Хадгалах
            </button>
        </div>
    )

}
   

export default RIGHT_PRODUCT_DETAIL
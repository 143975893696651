import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './Pages/App/App';
import { AuthProvider } from './Context/authContext';
import { BrowserRouter } from 'react-router-dom';
import { MessageProvider } from './Context/messageContext';
import { CategoryProvider } from './Context/categoryContext';
import { ProductProvider } from './Context/productContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ProductProvider>
    <CategoryProvider>
    <MessageProvider>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
    </MessageProvider>
    </CategoryProvider>
    </ProductProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

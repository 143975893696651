import React ,{useState} from "react";
import css from "./style.module.css"
import LIST_OF_CONTENT from "../../CONTAINER_CONTENTS/LIST_OF_CONTENT";
import LIST_OF_SELLED from "../../CONTAINER_CONTENTS/LIST_OF_SELLED";
import LIST_OF_DELETED from "../../CONTAINER_CONTENTS/LIST_OF_DELETED";
import LIST_OF_NOWHERE from "../../CONTAINER_CONTENTS/LIST_OF_NOWHERE";

const KONTENT = (props) => {
    const [containerType, setContainerType] = useState("content")
    return (
        <div className={css.containerFluid}>
            <p className={css.title}>Контент</p>
            <div className={css.hr}>

            </div>
            <div className={css.containerContentType}>
                <div className={css.buttonContent}>
                    <div className={css.buttonContentContainer} onClick={() => {setContainerType("content")}}>
                        <div className={css.iconContainer}>
                            <img src={require("../../../Assets/file.png")}/>
                        </div>
                        <div className={css.contentName}>
                            <p className={css.number}>12</p>
                            <p className={css.contentNameTxt}>
                                Нийт контент
                            </p>
                        </div>
                    </div>
                </div>
                <div className={css.buttonContent}>
                    <div className={css.buttonContentContainer} onClick={() => {setContainerType("selled")}}>
                        <div className={css.iconContainer}>
                            <img src={require("../../../Assets/add.png")}/>
                        </div>
                        <div className={css.contentName}>
                            <p className={css.contentNameTxt} style={{fontSize:"18px", margin:"0" , marginTop:"0", color:"white"}}>
                                Контент нэмэх
                            </p>
                        </div>
                    </div>
                </div>
             
                
                
            </div>
            <LIST_OF_CONTENT/> 
            
        </div>
    )
}

export default KONTENT
import React from "react";
import css from "./style.module.css"
import { useMessage } from "../../Context/messageContext";
import SHADOW from "../../Components/MAIN_APP/SHADOW";

const MESSAGE_MODAL = (props) => 
{   
    const {modalMessage, setModalMessage} = useMessage()

    return (
        <div className={`${css.containerFluid} ${modalMessage ? css.active : null}` } onClick={() => {setModalMessage("")}}>

            <div className={css.container}>
                <img src={require("../../Assets/check.png")} className={css.img}/>
                <p className={css.message}>{modalMessage}</p>
            </div>
        </div>
    )
}

export default MESSAGE_MODAL


     
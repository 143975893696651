import React from "react";
import css from "./style.module.css"
import { useCategory } from "../../../Context/categoryContext";
import { Link } from "react-router-dom";

const BOTTOM_NAV = (props) => { 
    
    const {categories} = useCategory()

    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <ul className={css.list}> 
                    {
                        categories && categories.length > 0 
                        
                        ? 
                        categories.map((e, i) => (
                           <Link to={`/files/${e.name}`} style={{textDecoration:"none"}}> <li key={i} className={css.listItem}>{e.name}</li></Link>
                        ))

                        : 

                        "Төрөл байхгүй байна."
                    }
                </ul>
            </div>
        </div>
    )
}

export default BOTTOM_NAV
import React, { createContext, useContext, useState } from "react";

const MessageContext = createContext()

export const MessageProvider = ({children}) =>{
    const [modalMessage, setModalMessage] = useState("")

    return (
        <MessageContext.Provider value={{modalMessage, setModalMessage}}>
            {children}
        </MessageContext.Provider>
    )
}

export const useMessage = () => {
    return useContext(MessageContext)
}
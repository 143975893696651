import React, { useState } from "react";
import css from "./style.module.css"
import CONTAINER_LOGIN from "../../Components/LOGIN/CONTAINER_LOGIN";
import CONTAINER_REGISTER from "../../Components/LOGIN/CONTAINER_REGISTER";
import SHADOW from "../../Components/MAIN_APP/SHADOW";
import { useAuth } from "../../Context/authContext";

const LOGIN = (props) => {
    const {isAuthenticated} = useAuth()
    const [isLogin, setIsLogin] = useState(false)
    
    if(!isAuthenticated)
    {
        return (
            <div className={`${css.containerFluid} ${props.modal ? css.active :css.deactive}`}>
                {
                   props.modal && <SHADOW onclick={props.setModal}/>
                } 
               {
                props.type === "login" ? 
                <CONTAINER_LOGIN setIsLogin={props.setType} setModal={props.setModal}/>
                :
                <CONTAINER_REGISTER setIsLogin={props.setType} setModal={props.setModal}/>
               }
            </div>
        )
    }
    
}

export default LOGIN
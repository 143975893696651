import React from "react";
import css from "./style.module.css"
import { Link } from "react-router-dom";

const BANNER_HOME = (props) => {

    const handleModal = (type) => {
        props.setModalType(type)
        props.setModal("register")
    }
 
    return (
        <div className={css.containerFluid}>
            <div className={css.bannerImageContainer}>
                <div className={css.bannerContentContainer}>
                    <p className={css.contentTitle}>Хязгааргүй файл байршуулалт болон хүссэн үнэ</p>
                    <p className={css.contentDesc}>Өөрийн эрхийг 1 сараар ердөө 20.000 төгрөгөөр сунгаарай</p>
                    <div className={css.btnContainer}>
                    <Link to={"/subscription"}><button className={css.btn}>Сунгах</button></Link>
                    <Link to={"/files/add/product"} style={{textDecoration:"none" , marginLeft:"20px"}}><button className={css.btn}>Файл байршуулах</button></Link>
                    
                    </div>
                </div>
            </div>
            <div className={css.subscriptionContainer}>
                <div className={css.subscription}>
                    <p className={css.titleSub}>Эхлэл</p>
                    <p className={css.price}>20.0000₮<label className={css.month}>/сар</label></p>
                    <div>
                    <div className={css.cons}>
                        <p className={css.consTxt}>Хягааргүй файл байршуулалт</p>
                    </div>
                    <div className={css.cons}>
                        <p className={css.consTxt}>Файл хянах хэсэг</p>
                    </div>
                    <div className={css.cons}>
                        <p className={css.consTxt}>Илүү орлого</p>
                    </div>
                    </div>
                    <Link to={"/subscription"}><button className={css.btnSub}>ЭРХ ҮҮСГЭХ</button></Link>
                </div>
            </div>
        </div>
    )
}

export default BANNER_HOME
import React, { useState } from "react";
import css from "./style.module.css"
import check from "../../../Assets/check.png"
import PRODUCT_CARD from "../../CARD/PRODUCT_CARD";
import { useCategory } from "../../../Context/categoryContext";
import { useProduct } from "../../../Context/productContext";

const FILES_CONTAINER = (props) => {
    const [active, setActive] = useState("Програм хангамж")
    const {categories} = useCategory()
    const {products} = useProduct()

    console.log(products)
    return (
        <div className={css.containerFluid}>
                <div className={css.left}>
                    <p className={css.leftTitle}>Файлын ангилал</p>
                    <ul className={css.leftList}>
                    <li className={css.leftListItem} onClick={()=>{setActive("Бүгд")}}> 
                            <div className={css.checkBox}>
                                {active === "Бүгд" && <img src={check} className={css.check}/>}
                            </div>
                            <label className={css.listName}>
                                Бүгд
                            </label>
                    </li>
                       
                    {
                        categories && categories.length > 0 
                        ? 
                        categories.map((e, i) => (
                            <li className={css.leftListItem} onClick={()=>{setActive(e.name)}} key={i}> 
                                    <div className={css.checkBox}>
                                    {active === e.name && <img src={check} className={css.check}/>}
                                    </div>
                                <label className={css.listName}>
                                    {e.name}
                                </label>
                            </li>
                            ))
                            : 
                            "Төрөл байхгүй байна"
                        }
                    </ul>
             
                </div>
                <div className={css.right}>
                    <div className={css.rightContainer}>
                        <p className={css.leftTitle} style={{color:"white"}}>{active}</p>
                        <div className={css.hr}></div>
                    </div>
                </div>
        </div>
    )
}   

export default FILES_CONTAINER
import React from "react";
import css from "./style.module.css"

const SUBSCRIPTION = (props) => {
    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <h2>Эрх сунгах</h2>
                <div className={css.packageContainer}>
                    <div className={css.packageContainerNav}>
                        <p className={css.packageName}>
                            Энгийн багц
                        </p>
                    </div>
                    <p className={css.packageTimeAndPrice}>20.000<label style={{fontSize:"14px"}}>/сар</label></p>
                    <p className={css.cons}>Хязгааргүй файл байршуулалт</p>
                    <p className={css.cons}>Файл хянах хэсэг</p>
                    <p className={css.cons}>Илүү орлого</p>
                    <button className={css.btn}>
                        Худалдан авах
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SUBSCRIPTION
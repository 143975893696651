import React, { useState } from "react";
import css from "./style.module.css"
import { useCategory } from "../../Context/categoryContext";
import { useAuth } from "../../Context/authContext";
import axios from "axios";
import { useMessage } from "../../Context/messageContext";

const FILES_ADD = (props) => {

    const {categories} = useCategory()
    const {user} = useAuth()
    const [image, setImage] = useState(null)
    const [productName, setProductName] = useState("")
    const [shortDesc, setShortDesc] = useState("")
    const [desc, setDesc] = useState("")
    const [price, setPrice] = useState("")
    const [link, setLink] = useState("")
    const [categoryName , setCategoryName] = useState("")
    
    const {modalMessage, setModalMessage} = useMessage()
    
    const handleImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
        }
    };

    const handleClick = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("username", user.username);
        formData.append("productName", productName);
        formData.append("shortDesc", shortDesc);
        formData.append("desc", desc);
        formData.append("price", price);
        formData.append("link", link);
        formData.append("categoryName", categoryName);
        formData.append("image", image)
        try 
        {
            
            const response = await axios.post("https://omn1group.com/api/v1/product", formData, {
                headers: {
                    "Content-Type": "multipart/form-data", 
                },
            });
            if(response.status === 200)
            {
                setModalMessage("Файлыг амжилттай нэмлээ")
            }
        }
        catch(err)
        {
            setModalMessage(err.response.data.message)
        }
    }

    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <p className={css.title}>Файл байршуулах</p>
                <div className={css.containerAdd}>
                    <div className={css.imageContainer}>
                        {
                            !image ? 
                            <>
                            <div className={css.textImage}>
                            <img src={require("../../Assets/imageUpload.png")} className={css.icon} /> 
                            Зураг оруулах
                            </div>
                            <input type="file" className={css.input} accept="image/*" 
                            onChange={handleImage} /> </> 
                            :
                            <img src={URL.createObjectURL(image)} className={css.uploadedImage}/>
                        }
                    </div>
                    <div className={css.detailContainer}>   
                            <select className={css.option} onChange={(e) => {setCategoryName(e.target.value)}}>
                                <option value={null}>Төрлийг сонгох</option>
                                {
                                    categories.map((e, i) => (
                                        <option value={e.name}>{e.name}</option>
                                    ))
                                }

                            </select>
                            <input type="text" className={css.inputS} placeholder="Файлын гарчиг" onChange={(e) => {setProductName(e.target.value)}}/>
                            <input type="text" className={css.inputSs} placeholder="Файлын зарагдах үнэлгээ" onChange={(e) => {setPrice(e.target.value)}}/>
                            <textarea className={css.textArea} placeholder="Файлын богино тайлбар 255 үсэгтэй" onChange={(e) => {setShortDesc(e.target.value)}}/>
                            <textarea className={css.textAreaLong} placeholder="Файлын богино тайлбар 500 үсэгтэй" onChange={(e) => {setDesc(e.target.value)}}/>
                            <input type="text" style={{marginTop:"20px", height:"30px", paddingLeft:"5px", borderRadius:"5px", border:"1px solid gray"}} placeholder="Файлын линк" onChange={(e) => {setLink(e.target.value)}}/>
                            <button className={css.btn} onClick={handleClick}>Оруулах</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default FILES_ADD
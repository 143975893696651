import React from "react";
import css from "./style.module.css"
import { Link } from "react-router-dom";

const FOOTER = (props) => {
    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                 <div className={css.leftContainer}>
                    <div className={css.logo}>
                        <img src={require("../../Assets/logo.png")}/>
                        <div className={css.searchContainer}>
                            <input className={css.search} placeholder="Хайх"/>
                            <div className={css.iconContainer}>
                                <img src={require("../../Assets/search.png")} className={css.icon} />
                            </div>
                        </div>
                    </div>
                 </div>
                 <div className={css.rightContainer}>
                    <ul className={css.list}>
                        <p className={css.listTitle}>Холбоо барих</p>
                        <li className={css.listItem}>Утас: 99765814</li>
                    </ul>
                    <ul className={css.list}>
                        <p className={css.listTitle}>Хуудас</p>
                        <Link to={"/"} className={css.link}>
                        <li className={css.listItem}>Эхлэл</li>
                        </Link>
                        <Link to={"/categories"} className={css.link}>
                        <li className={css.listItem}>Төрөл</li>
                        </Link>
                        <Link className={css.link} to={"/profile/:id"}>
                        <li className={css.listItem}>Хэрэглэгч</li>
                        </Link>
                    </ul>
                 </div>
            </div>
        </div>
    )
}

export default FOOTER
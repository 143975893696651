import React from "react";
import css from "./style.module.css"

const LEFT_PRODUCT_DETAIL = (props) => {
    
    
    return (
        <div className={css.containerFluid}>
            <div className={css.containerImage}>
                <img src={require("../../../Assets/276454.jpg")} className={css.image}/>
            </div>
            <div className={css.containerDescription}>
                <p className={css.descTitle}>Тайлбар:</p>
                <p className={css.desc}>
                   {props.product && props.product.description ? props.product.description : "Тайлбар оруулаагүй" }
                </p>
            </div>
        </div>
    )

}
   

export default LEFT_PRODUCT_DETAIL
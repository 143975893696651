import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState({
        username: "",
        email: "",
        role: "",
    });
    const [subscription, setSubscription] = useState({
        startDate: "",
        endDate: "",
    });

    const login = (details) => {
        setIsAuthenticated(true);
        setUser({
            username: details.username,
            email: details.email, 
            role: details.role,
        });
    };

    const updateSubscription = (subscriptionDetails) => {
        setSubscription({
            startDate: subscriptionDetails.startDate,
            endDate: subscriptionDetails.endDate,
        });
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUser({
            username: "",
            email: "",
            role: "user",
        });
        setSubscription({
            startDate: "",
            endDate: "",
        });
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                subscription,
                login,
                updateSubscription,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
